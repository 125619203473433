/*=========================================================================================
 File Name: wizard-steps.js
 Description: wizard steps page specific js
 ----------------------------------------------------------------------------------------
 Item Name: Robust - Responsive Admin Theme
 Version: 1.0
 Author: PIXINVENT
 Author URL: http://www.themeforest.net/user/pixinvent
 ==========================================================================================*/

// Wizard tabs with icons and numbers setup
function toggleVideo(id) {
    if ($(id).find('.needs-camera').length > 0) {
        $('.video-section').removeClass('hide');
    } else {
        $('.video-section').addClass('hide');
    }
}


$(".icons-tab-steps, .number-tab-steps").steps({
    headerTag: "h6",
    bodyTag: "fieldset",
    transitionEffect: "fade",
    titleTemplate: '<span class="step">#index#</span> #title#',
    labels: {
        previous: translations['interface.icare4.buttons.prev'],
        next: translations['interface.icare4.buttons.next'],
        finish: translations['interface.icare4.buttons.save']
    },
    onInit: function (event, currentIndex) {
        var currentId = 'steps-uid-0-p-' + currentIndex;
        $('#' + currentId + ' .royalSlider').royalSlider({
            fullscreen: {
                enabled: true,
                nativeFS: true
            },
            controlNavigation: 'thumbnails',
            thumbs: {
                orientation: 'vertical',
                paddingBottom: 4,
                appendSpan: true
            },
            transitionType: 'fade',
            autoScaleSlider: true,
            autoScaleSliderWidth: 960,
            autoScaleSliderHeight: 600,
            loop: true,
            arrowsNav: true,
            keyboardNavEnabled: false,
            navigateByClick: false,
            sliderTouch: false
        }).css('visibility', 'visible');
        toggleVideo('#' + currentId);


        // Set title page in left side where it's the camerax
        if($('#' + currentId).has('needs-video')) {
            $('.page-title-over-video').html($('#' + currentId).find('.page-title').html());
            $('.page-info-over-video').html($('#' + currentId).find('.page-info').html());
        }

        $('#icon-tabs .actions a').addClass('btn-primary');
        var $input = $('<li class="hardware-integration-button" aria-disabled="false"><a data-action="read_hw" role="menuitem" class="btn-primary" disabled="disabled">' + translations['interface.icare4.hardware.buttons.start'] + '</a></li><li class="hardware-integration-button" aria-disabled="false"><a data-action="reset_hw" role="menuitem" class="btn-primary" disabled="disabled">' + translations['interface.icare4.hardware.buttons.reset'] + '</a></li>');
        $('ul[aria-label="Pagination"] li').first().after($input);

        if ($('#' + currentId).hasClass('hardware-integration-measurement')) {
            $('ul[aria-label="Pagination"] .hardware-integration-button').removeClass('hide');
        } else {
            $('ul[aria-label="Pagination"] .hardware-integration-button').addClass('hide');
        }

        if (typeof(functionsForPage) == "function") {
            functionsForPage(currentIndex);
        }

        // Initialize click events for images
        if ($('#' + currentId + ' .royalSlider').length > 0) {
            $('#' + currentId + ' .royalSlider').data('royalSlider').ev.on('rsAfterContentSet', function () {
                $('.question-value-for-images').unbind('click').click(function () {
                    if ($(this).find('input').is(':checked') !== $('input[id="' + $(this).find('input').attr('data-target') + '"').is(':checked')) {
                        $('input[id="' + $(this).find('input').attr('data-target') + '"').closest('.question-value').trigger('click');
                    }
                });
            });
        }
    },
    onStepChanged: function (event, currentIndex, newIndex) {
        var currentId = 'steps-uid-0-p-' + currentIndex;
        $('#' + currentId + ' .royalSlider').royalSlider({
            fullscreen: {
                enabled: true,
                nativeFS: true
            },
            controlNavigation: 'thumbnails',
            thumbs: {
                orientation: 'vertical',
                paddingBottom: 4,
                appendSpan: true
            },
            transitionType: 'fade',
            autoScaleSlider: true,
            autoScaleSliderWidth: 960,
            autoScaleSliderHeight: 600,
            loop: true,
            arrowsNav: true,
            keyboardNavEnabled: false,
            navigateByClick: false,
            sliderTouch: false
        }).css('visibility', 'visible');

        $('.curr-value-decoration-up.media').css('left', "50%");
        $('.curr-value-decoration-down.media').css('left', "50%");

        // Set title page in left side where it's the camera
        if($('#' + currentId).has('needs-video')) {
            $('.page-title-over-video').html($('#' + currentId).find('.page-title').html());
            $('.page-info-over-video').html($('#' + currentId).find('.page-info').html());

            // New BIND of click event because it's changed the content of .page-title-over-video and .page-info-over-video elements during step changed
            $('.page-title-over-video .question-help-button, .page-info-over-video .question-help-button').each(function () {
                $(this).unbind('click').click(function () {
                    bootbox.alert({message: $(this).find('.question-help-text').html(), animate: false});
                });
            });
        }

        // Initialize click events for images
        if ($('#' + currentId + ' .royalSlider').length > 0) {
            $('#' + currentId + ' .royalSlider').data('royalSlider').ev.on('rsAfterContentSet', function () {
                $('.question-value-for-images').unbind('click').click(function () {
                    if ($(this).find('input').is(':checked') !== $('input[id="' + $(this).find('input').attr('data-target') + '"').is(':checked')) {
                        $('input[id="' + $(this).find('input').attr('data-target') + '"').closest('.question-value').trigger('click');
                    }
                });
            });
        }

        if (typeof(functionsForPage) == "function") {
            functionsForPage(currentIndex);
        }
    },
    onFinished: function (event, currentIndex) {
        $('.icons-tab-steps fieldset').each(function () {
            var currentId = 'steps-uid-0-p-' + currentIndex;
            if ($(this).attr('id') == currentId) {
                $(this).find('input, textarea').not('.always-no-validate').removeClass('no-validate');
            } else {
                $(this).find('input, textarea').not('.always-no-validate').addClass('no-validate');
            }
        });

        if ($(this).closest('form').valid() && $('a[href="#finish"]').attr('disabled') != 'disabled') {
            $('a[href="#finish"]').attr('disabled', 'disabled');

            return $(this).closest('form').submit();
        } else {
            return false;
        }
    },
    onStepChanging: function (event, currentIndex, newIndex) {
        var currentId = 'steps-uid-0-p-' + currentIndex;
        var newId = 'steps-uid-0-p-' + newIndex;

        $('.icons-tab-steps fieldset').each(function () {
            if ($(this).attr('id') == currentId) {
                $(this).find('input, textarea').not('.always-no-validate').removeClass('no-validate');
            } else {
                $(this).find('input, textarea').not('.always-no-validate').addClass('no-validate');
            }
        });

        if ($('#' + newId).hasClass('hardware-integration-measurement')) {
            $('ul[aria-label="Pagination"] .hardware-integration-button').removeClass('hide');
        } else {
            $('ul[aria-label="Pagination"] .hardware-integration-button').addClass('hide');
        }

        if ((currentIndex > newIndex) || $(this).closest('form').valid()) {
            toggleVideo('#' + newId);
            return true;
        } else {
            return false;
        }
    }
});
