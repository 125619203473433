/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

$(document).ready(function () {

    history.pushState(null, null, location.href);
    window.onpopstate = function () {
        history.go(1);
    };

    if ($('meta[name="show-pre-selected-product-brands-popup"]').attr('content') == true) {
        bootbox.confirm({
            animate: false,
            message: translations['interface.icare4.business.settings.products.brands.popup'],
            buttons: {
                confirm: {
                    label: translations['interface.icare4.buttons.configure'],
                    className: 'btn btn-danger white'
                },
                cancel: {
                    label: translations['interface.icare4.buttons.cancel'],
                    className: 'btn btn-primary white'
                }
            },
            callback: function (result) {
                if (result == true) {
                    window.location.href = $('meta[name="base-url"]').attr('content') + '/business/preSelectedProductBrands';
                } else {
                    var $csrfToken = $('meta[name="csrf-token"]').attr('content');
                    var $baseUrl = $('meta[name="base-url"]').attr('content');
                    $.post($baseUrl + "/business/emptyPreSelectedProductBrands", {
                        emptyPreSelectedProductBrands: $('#emptyPreSelectedProductBrands').is(':checked'),
                        _token: $csrfToken
                    });
                }
            }
        });
    }

    $('img.pharmacy-logo').each(function () {
        $(this).attr('src', $(this).data('original-src'));
    }).error(function () {
        $(this).attr('src', $(this).data('alternative-src'));
    });

    $(document).on('shown.bs.modal', '.modal', function () {
        $(this).addClass('animated pulse');
    });

    $('.show-widget').click(function () {
        $($(this).attr('data-target-widget')).removeClass('hide');
    });

    $('.hide-this-widget').click(function () {
        $(this).closest('.widget').addClass('hide');
    });

    $('.business-header').click(function () {
        bootbox.alert({
            message: '<div><strong>Nome: </strong>' + $('meta[name="business-id"]').attr('content') + '</div>' +
                '<div><strong>ID: </strong>' + $('meta[name="business-id"]').attr('content') + '</div>' +
                '<div><strong>Session ID: </strong>' + $('meta[name="session-id"]').attr('content') + '</div>',
            animate: false
        });
    });

    $('form:not(.no-validate)').each(function () {
        $(this).validate({
            ignore: '.no-validate',
            onsubmit: false,
            onfocusout: false,
            onclick: false,
            onkeyup: false,
            showErrors: function (errorMap, errorList) {
                $('.render-error.error').removeClass('error');
                $('.validation-message').html('');
                $.each(errorList, function (index, value) {

                    if ($(value.element).closest('fieldset').attr('aria-hidden') == "false") {
                        $(value.element).closest('.question-wrapper').find('.validation-message').first().html(value.message);
                        $(value.element).closest('.question-wrapper').find('.render-error').first().addClass('error');
                    }

                });
                return true;
            }
        });
    });

    function stopVideo(iframe) {
        iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }

    $('a[data-toggle="custom-modal"]').click(function (e) {

        $('#checkupModal').find('input[name="selectedCheckupId"]').val($(this).data('checkup-id'));
        $('#checkupModal').find('.checkup-archive-button').data('target-checkup-id', $(this).data('checkup-id'));
        $('#checkupModal').find('.title').first().html($(this).data('checkup-name'));
        $('#checkupModal').find('.modal-category').first().html($(this).data('checkup-category-name'));
        $('#checkupModal').find('.modal-header').first().removeClass(function (index, css) {
            return (css.match(/bg-[a-z]*/g) || []).join(' '); // remove old class selected
        });
        $('#checkupModal').find('.checkup-content').addClass('hide');
        $('#checkupModal').find('.booking').addClass('hide');
        $('#checkupModal').find('.bookings-title').addClass('hide');
        var checkupContent = $('#checkupModal').find('#' + $(this).data('checkup-id') + 'Content');
        checkupContent.removeClass('hide');
        $('#checkupModal').find('.modal-header').first().addClass('bg-' + $(this).data('checkup-category'));
        $('#checkupModal').find('.modal-title img').first().attr('src', $(this).data('checkup-img'));
        $('#checkupModal').find('.modal-title img').first().attr('src', $(this).data('checkup-img'));
        $('#checkupModal').find('.booking[data-checkup-name="' + $(this).data('checkup-id') + '"]').each(function () {
            $('#checkupModal').find('.bookings-title').removeClass('hide');
            $(this).removeClass('hide');
        });
        $('#checkupModal').modal('show');
        if (checkupContent.attr('data-checkup-video')) {
            $('#checkupModal').find('.checkup-video').first().attr('src', checkupContent.data('checkup-video'));
            $('#checkupModal').find('.checkup-video-wrapper').removeClass('hide');
            $('#checkupModal').find('.checkup-description-wrapper').removeClass('col-xl-12');
            $('#checkupModal').find('.checkup-description-wrapper').addClass('col-xl-6');
            if ($('#video-wrapper').hasClass('hidden')) {
                $('#checkupModal').find('.checkup-description-wrapper').removeClass('col-xl-6');
                $('#checkupModal').find('.checkup-description-wrapper').addClass('col-xl-12');
            }
        } else {
            $('#checkupModal').find('.checkup-video-wrapper').addClass('hide');
            $('#checkupModal').find('.checkup-description-wrapper').addClass('col-xl-12');
            $('#checkupModal').find('.checkup-description-wrapper').removeClass('col-xl-6');
        }
        if ($('#switchBootstrap9').length > 0) {
            $('#checkupModal').find('input[name="selectedGenre"]').attr('value', ($('#switchBootstrap9').is(':checked') ? 'female' : 'male'));
        }

        $('#checkupModal').find('.checkup-archive-button').click(function () {
            window.location = $(this).data('target-url') + '/' + $(this).data('target-checkup-id');
        });

        $('#checkupModal').find('.checkup-archive-button').click(function () {
            window.location = $(this).data('target-url') + '/' + $(this).data('target-checkup-id');
        });

        player = new YT.Player('ytplayer', {
            events: {
                'onStateChange': onPlayerStateChange
            }
        });

        if ($(this).data('checkup-executable') == false) {
            $('#checkupModal .make-checkup-button').addClass('hide');
        } else {
            $('#checkupModal .make-checkup-button').removeClass('hide')
        }
    });

    $('.checkup-modal').on('hide.bs.modal', function (e) {
        stopVideo($(this).find("iframe")[0].contentWindow);
    });

    function onPlayerStateChange(event) {
        if ($(document).width() < 1200) {
            return;
        }
        if (event.data == YT.PlayerState.PLAYING) {
            $('.checkup-description-wrapper').addClass('hide');
            $('.checkup-video-wrapper').removeClass('col-xl-6');
            $('.checkup-video-wrapper').addClass('col-xl-12');

            $('.modal-header').addClass('hide');
        } else if (event.data == YT.PlayerState.PAUSED || event.data == YT.PlayerState.ENDED) {
            $('.checkup-description-wrapper').removeClass('hide');
            $('.checkup-video-wrapper').addClass('col-xl-6');
            $('.checkup-video-wrapper').removeClass('col-xl-12');

            $('.modal-header').removeClass('hide');
        }
    }


    function getBase64Image(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }

    function decodeImageFromBase64(data, callback) {
        qrcode.callback = callback;
        qrcode.decode(data);
    }

    getQrCode();
    var IoTDeviceQrCodeInterval = setInterval(getQrCode, 5000);

    function getQrCode() {
        if ($('meta[name="iot-device-id"]').attr('content') == "" || $('meta[name="iot-device-id"]').attr('content').indexOf("IOT") != 0) {
            console.log("Try to get iot-device-id");
            var IoTDeviceQrCode = $("#iot-device-qr-code");
            if (IoTDeviceQrCode.length > 0) {
                try {
                    var IoTDeviceQrCodeBase64 = getBase64Image(document.getElementById('iot-device-qr-code'));
                    decodeImageFromBase64("data:image/png;base64," + IoTDeviceQrCodeBase64, function (IoTDeviceId) {
                        if (IoTDeviceId.indexOf("IOT") == 0) {
                            var url = IoTDeviceQrCode.data('target-select-url').replace('__ID__', IoTDeviceId);
                            $.get(url, function (data) {
                                $('meta[name="iot-device-id"]').attr('content', JSON.parse(data).id);
                            });
                        }
                    });
                } catch (e) {
                    console.log('iot-device not found');
                }
            }
        } else {
            console.log("iot-device-id is set. Clear interval.");
            clearInterval(IoTDeviceQrCodeInterval);
        }
    }

    var updateAppointment = function (booking) {
        if (booking.status != "active") {
            $('.booking' + booking.date).each(function (index, item) {
                if (booking.checkupModelId == $(item).data('correct-checkup-id')) {
                    $(item).find('.booking-unavailable').addClass('hide');
                    $(item).data('client', '');
                    $(item).data('phone', '');
                    $(item).data('email', '');
                    $(item).data('disabled', false);
                    $(item).find('.booking-available').removeClass('hide');
                }
            });
        } else if (booking.status == "active") {
            $('.booking' + booking.date).each(function (index, item) {
                if (booking.checkupModelId == $(item).data('correct-checkup-id')) {
                    $(item).find('.booking-available').addClass('hide');
                    $(item).find('.booking-unavailable').removeClass('hide');
                    $(item).data('id', booking.id);
                    if (booking.additionalData.clientInfo && booking.additionalData.clientInfo.fullName) {
                        $(item).data('client', booking.additionalData.clientInfo.fullName);
                        $(item).find('.booking-name').text(booking.additionalData.clientInfo.fullName);
                        $(item).data('disabled', true);
                    }
                    if (booking.additionalData.clientInfo && booking.additionalData.clientInfo.phone) {
                        $(item).data('phone', booking.additionalData.clientInfo.phone);
                    }
                    if (booking.additionalData.clientInfo && booking.additionalData.clientInfo.email) {
                        $(item).data('email', booking.additionalData.clientInfo.email);
                    }
                    if (booking.additionalData.clientInfo && booking.additionalData.clientInfo.address) {
                        $(item).data('address', booking.additionalData.clientInfo.address);
                    }
                    if (booking.additionalData.nrPartecipants) {
                        $(item).data('nrPartecipants', booking.additionalData.nrPartecipants);
                    }
                }
            });
        }
    }

    $('.business-booking .book-appointment-btn').click(function () {
        var $appointments = $('.business-booking-' + $(this).data('business-booking-id')).find('.appointments');
        var $wait = $('.business-booking-' + $(this).data('business-booking-id')).find('.appointments-wait');
        var $csrfToken = $('meta[name="csrf-token"]').attr('content');
        var $baseUrl = $('meta[name="base-url"]').attr('content');
        var eventDate = $appointments.data('event-date');
        $appointments.addClass('hide');
        $wait.removeClass('hide');
        $.post($baseUrl + "/booking/appointments", {
                eventDate: eventDate,
                _token: $csrfToken
            })
            .done(function (response) {
                console.log(response);
                for (var date in response) {
                    updateAppointment(response[date]);
                }
                $wait.addClass('hide');
                $appointments.removeClass('hide');
            })
            .fail(function (response) {
                console.log(JSON.stringify(response));
                bootbox.alert({
                    message: translations["interface.icare4.alert.error"],
                    animate: false
                });
            });
    });

    $('.checkup-modal-dialog .book-appointment-btn').click(function () {
        var $baseUrl = $('meta[name="base-url"]').attr('content');
        if ($(this).parents('.checkup-modal-dialog').find('.booking').not('.hide').length > 0) {
            if ($('.homepage-switch .btn-group').length > 0) {
                $('.homepage-switch .btn-group').bootstrapSwitch('state', false, false);
            } else {
                window.location.replace($baseUrl + "/booking/bookings");
            }
        } else {
            window.location.replace($baseUrl + "/booking/calendar");
        }
    });

    $('.list-appointments-btn').click(function () {
        var id = $(this).data('business-booking-id');
        var $baseUrl = $('meta[name="base-url"]').attr('content');
        window.location.replace($baseUrl + "/booking/export/" + id);
    });

    $('.close-appointments-btn').click(function () {
        $(this).parents('.appointments').addClass('hide');
    });

    $('.booking-modal-btn').click(function (e) {
        var $appointment = $(this).parent('.appointment');
        var bookingDate = $appointment.data('checkup-date');
        bookingDate = bookingDate.split("/");
        bookingDate = bookingDate[1] + "/" + bookingDate[0] + "/" + bookingDate[2];
        bookingDate = new Date(bookingDate).getTime();
        console.log(bookingDate);
        $('#bookingModal').find('input[name="selectedCheckupId"]').val($appointment.data('correct-checkup-id'));
        $('#bookingModal').find('input[name="checkup"]').val($appointment.data('correct-checkup-id'));
        $('#bookingModal').find('input[name="hours"]').val($appointment.data('hours'));
        $('#bookingModal').find('input[name="minutes"]').val($appointment.data('minutes'));
        $('#bookingModal').find('input[name="fullName"]').val($appointment.data('client'));
        $('#bookingModal').find('input[name="phone"]').val($appointment.data('phone'));
        $('#bookingModal').find('input[name="email"]').val($appointment.data('email'));
        $('#bookingModal').find('input[name="nrPartecipants"]').val($appointment.data('nrPartecipants'));
        $('#bookingModal').find('input[name="address"]').val($appointment.data('address'));
        $('#bookingModal').find('.selected-date-input').val(bookingDate);
        $('#bookingModal').find('.title').first().html($appointment.data('checkup-name') + ' - ' + $appointment.data('checkup-date') + ' - ' + $appointment.data('hours') + ':' + $appointment.data('minutes'));
        $('#bookingModal').find('.modal-category').first().html($appointment.data('checkup-category-name'));
        $('#bookingModal').find('.modal-header').first().removeClass(function (index, css) {
            return (css.match(/bg-[a-z]*/g) || []).join(' '); // remove old class selected
        });
        $('#bookingModal').find('.checkup-content').addClass('hide');
        var checkupContent = $('#bookingModal').find('#' + $appointment.data('checkup-id') + 'Content');
        checkupContent.removeClass('hide');
        $('#bookingModal').find('.modal-header').first().addClass('bg-' + $appointment.data('checkup-category'));
        $('#bookingModal').find('.modal-title img').first().attr('src', $appointment.data('checkup-img'));
        $('#bookingModal').find('.modal-title img').first().attr('src', $appointment.data('checkup-img'));
        if ($appointment.data('disabled')) {
            $('.privacy-yes').prop('checked', true);
            $('#bookingModal').find('input').prop('disabled', true);
            $('.btn.save-booking').addClass('hide');
            $('.btn.delete-booking').removeClass('hide');
            $('.btn.delete-booking').data('id', $appointment.data('id'));
        } else {
            $('#bookingModal').find('input').prop('disabled', false);
            $('.btn.delete-booking').addClass('hide');
            $('.btn.save-booking').removeClass('hide');
            $('[name="privacy"]').prop('checked', false);
        }
        var form = $('#bookingModal').find('form');
        var Validator = form.validate();
        Validator.resetForm();
        form.find(".error").removeClass("error");
        form.find('.validation-message').empty();
        drawInitAllFields();
        $('#bookingModal').modal('show');
    });

    $('.save-booking').click(function () {
        var form = $(this).closest('form');
        var button = $(this);
        if (form.valid() && $(this).attr('disabled') != 'disabled') {
            $(this).attr('disabled', 'disabled');
            var clientInfo = {
                fullName: form.find('[name="fullName"]').val(),
                phone: form.find('[name="phone"]').val()
            }
            if (form.find('[name="email"]').val() != "") {
                clientInfo['email'] = form.find('[name="email"]').val();
            }
            if (form.find('[name="address"]') != null && form.find('[name="address"]').val() != "") {
                clientInfo['address'] = form.find('[name="address"]').val();
            }

            var newEntry = {
                time: parseInt((moment(parseInt(form.find('.selected-date-input').val()))).hour(parseInt(form.find('[name="hours"]').val())).minute(parseInt(form.find('[name="minutes"]').val())).format('X')) * 1000,
                checkup: form.find('[name="checkup"]').val(),
                clientInfo: JSON.stringify(clientInfo)
            }

            if (form.find('[name="nrPartecipants"]') != null && form.find('[name="nrPartecipants"]').val() != "") {
                newEntry['nrPartecipants'] = form.find('[name="nrPartecipants"]').val();
            }

            var $csrfToken = $('meta[name="csrf-token"]').attr('content');
            var $baseUrl = $('meta[name="base-url"]').attr('content');
            $.post($baseUrl + "/booking/add", {
                    inputs: newEntry,
                    _token: $csrfToken
                })
                .done(function (response) {
                    var booking = JSON.parse(response);
                    updateAppointment(booking);
                    $('#bookingModal').modal('hide');
                    bootbox.alert({
                        message: translations["interface.icare4.alert.success"],
                        animate: false
                    });
                })
                .fail(function (response) {
                    $('#bookingModal').modal('hide');
                    if (response.status == 501) {
                        bootbox.alert({
                            message: translations['interface.icare4.bookings.alert.alreadyInserted'],
                            animate: false
                        });
                    } else {
                        bootbox.alert({
                            message: translations['interface.icare4.alert.error'],
                            animate: false
                        });
                    }
                })
                .always(function (response) {
                    button.removeAttr('disabled');
                });
        } else {
            return false;
        }
    });

    $('.delete-booking').click(function () {
        var id = $(this).data('id');
        $('#bookingModal').modal('hide');

        bootbox.confirm({
            animate: false,
            message: translations['interface.icare4.calendar.messages.deleteConfirm'],
            buttons: {
                confirm: {
                    label: translations['interface.icare4.yes'],
                    className: 'btn btn-primary white'
                },
                cancel: {
                    label: translations['interface.icare4.no'],
                    className: 'btn btn-primary white'
                }
            },
            callback: function (result) {
                if (result == true) {

                    var $csrfToken = $('meta[name="csrf-token"]').attr('content');
                    var $baseUrl = $('meta[name="base-url"]').attr('content');
                    $.post($baseUrl + "/booking/delete", {
                            id: id,
                            _token: $csrfToken
                        })
                        .done(function (response) {
                            var booking = JSON.parse(response);
                            updateAppointment(booking);
                            bootbox.alert({
                                message: translations['interface.icare4.calendar.messages.deleted'],
                                animate: false
                            });
                        }).fail(function (response) {
                            bootbox.alert({
                                message: translations['interface.icare4.alert.error'],
                                animate: false
                            });
                        });
                }
            }
        });
    });

    $('.homepage-switch .btn-group .btn:nth-child(1)').click();

    var homepageInterval = null;
    var selectedCheckupsTab = true;
    var changeHomepageTab = function (viewType) {
        $('.modal').modal('hide');
        if ($('.homepage-checkups-tab').hasClass('hide') && viewType == 'checkupView') {
            $('.homepage-bookings-tab').addClass('hide');
            $('.homepage-checkups-tab').removeClass('hide');
        } else if ($('.homepage-bookings-tab').hasClass('hide') && viewType == 'bookingsView') {
            $('.homepage-bookings-tab').removeClass('hide');
            $('.homepage-checkups-tab').addClass('hide');
        }
        resetHomepageInterval();
    }

    var resetHomepageInterval = function () {
        clearInterval(homepageInterval);
        homepageInterval = setInterval(function () {
            if (selectedCheckupsTab == true) {
                $('.homepage-switch .btn-group .btn:nth-child(2)').click();
            } else {
                $('.homepage-switch .btn-group .btn:nth-child(1)').click();
            }
        }, 120000);
    }

    if ($('.homepage-bookings-tab').length > 0 && $('.homepage-checkups-tab').length > 0) {
        $('.homepage-checkups-tab').removeClass('hide');
        resetHomepageInterval();
    } else {
        $('.homepage-tab').removeClass('hide');
    }

    var resetSections = ['homepage-tab', 'checkup-modal-dialog', 'booking-modal-dialog', 'privacy-modal-dialog']
    resetSections.forEach(function (sectionClass) {
        $('.' + sectionClass).click(function () {
            console.log('reset interval');
            resetHomepageInterval();
        });
    });

    $('.homepage-switch .btn-group .btn:nth-child(1)').on('click', function (event, state) {
        changeHomepageTab('checkupView');
        selectedCheckupsTab = true;
    });

    $('.homepage-switch .btn-group .btn:nth-child(2)').on('click', function (event, state) {
        changeHomepageTab('bookingsView');
        selectedCheckupsTab = false;
    });

    $('meta[name="show-pre-selected-product-brands-popup"]').attr('content')


    $('.qr-code').click(function() {
        $('#preventitLogo').find('img').first().attr('src', $(this).data('preventit-img'));
        var $baseUrl = $('meta[name="base-url"]').attr('content');
        bootbox.alert({
            title: "<strong><h3>" + translations['interface.icare4.preventit.bootbox.title'] + "</strong></h3>",
            message: "<div class='qr-code-dialog'><p>" + translations['interface.icare4.preventit.bootbox.message'] + "</p><div class='qr-code-logo'><img src='" +  $baseUrl + "/imgs/preventitLogo.png' alt=''><img src='" +  $baseUrl + "/imgs/preventit-qrcode2.png' alt=''><div class='markets-logo'><img src='" +  $baseUrl + "/imgs/logos/android.png' alt=''><img src='" +  $baseUrl + "/imgs/logos/apple.png' alt=''></div></div></div>",
            size: 'large'
        });
    });
            

    });