/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

function drawSelectedValue(element) {
    element.closest('.question-value').addClass('bg-medispa');
    element.parent('.select-this').addClass('selected-this');
    element.parent('.select-this').find('.check-checked').css('display', 'block');
    $('.question-value-for-images input[data-target="' + element.attr('id') + '"]').attr('checked', 'checked');
    $('.question-value-for-images input[data-target="' + element.attr('id') + '"]').prop('checked', true);
}

function drawUnselectedValue(element) {
    element.closest('.question-value').removeClass('bg-medispa');
    element.parent('.select-this').removeClass('selected-this');
    element.parent('.select-this').find('.check-checked').css('display', 'none');
    $('.question-value-for-images input[data-target="' + element.attr('id') + '"]').removeAttr('checked');
    $('.question-value-for-images input[data-target="' + element.attr('id') + '"]').prop('checked', false);
}

function drawInitAllFields() {
    $('.question-value input[type="checkbox"]:checked, .question-value input[type="radio"]:checked').each(function () {
        drawSelectedValue($(this));
    });

    $('.question-values').find('.question-value input:not(:checked)').each(function () {
        drawUnselectedValue($(this));
    });
}

function fillFields(fieldName, value) {
    var field = $('input[name="' + fieldName + '"], textarea[name="' + fieldName + '"], input[name="' + fieldName + '[]"]');
    if (field.is(':input') && field.attr('type') == 'checkbox') {
        var values = value.split(',');
        values.forEach(function (value) {
            $('input[name="' + fieldName + '[]"][value="' + value + '"]').closest('.question-value').click();
        });
    } else if (field.is(':input') && field.attr('type') == 'radio') { 
       $('input[name="' + fieldName + '"][value="' + value + '"]').closest('.question-value').click();
    } else if (field.is(':input') && field.attr('type') == 'number') {
        $('input[name="' + fieldName + '"]').val(value);
    } else if (field.is(':input') && field.attr('type') == 'text') {
        $('input[name="' + fieldName + '"]').val(value);
    } else if (field.is('textarea')) {
        $('textarea[name="' + fieldName + '"]').val(value);
        
    } else {
        bootbox.alert(translations['interface.icare4.alert.error']);
    }
}

$(document).ready(function () {
    // Initialize values checked
    $(this).find('.question-value input[type="checkbox"]:checked, .question-value input[type="radio"]:checked').each(function () {
        drawSelectedValue($(this));
    });

    $('.question-value').click(function () {
        $(this).find('.question-input[type="radio"], .question-input[type="checkbox"]').each(function () {

            if ($(this).is(':checked')) {
                $(this).prop('checked', false);
                $(this).removeAttr('checked');
                drawUnselectedValue($(this));
            } else {
                $(this).prop('checked', true);
                $(this).attr('checked', 'checked');
                drawSelectedValue($(this));
            }

            if ($(this).val() != 'none') {
                $(this).closest('.question-values').find('input[value="none"]').each(function () {
                    $(this).prop('checked', false);
                    $(this).removeAttr('checked');
                    drawSelectedValue($(this));
                });
            } else {
                $(this).closest('.question-values').find('input[value!="none"]').each(function () {
                    $(this).prop('checked', false);
                    $(this).removeAttr('checked');
                    drawUnselectedValue($(this));
                });
            }

            // remove selection to other inputs
            $(this).closest('.question-values').find('.question-value input:not(:checked)').each(function () {
                drawUnselectedValue($(this));
            });

        });
    });

    $('.checkup .question-wrapper .question-values').each(function () {
        var $numberOfValues = $(this).attr('number-of-values');
        if ($numberOfValues > 1) {
            $(this).find('input').click(function (event) {
                if ($(this).prop('checked')) {
                    if ($(this).closest('.question-values').find('input:checked').length > $numberOfValues) {
                        bootbox.alert({message: 'Selezionare al massimo ' + $numberOfValues + ' valori', animate: false});
                    } else {
                        $(this).attr('checked', 'checked');
                    }
                }
            });
        }
    });
}
);
