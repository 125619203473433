/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */



$(document).ready(function () {

    if($('.iot-management-software-icon').length > 0) {
        $('.iot-management-software-icon').click(function(){
            if($('.iot-management-software-status').hasClass('hide')) {
                $('.iot-management-software-status').removeClass('hide');
            } else {
                $('.iot-management-software-status').addClass('hide');
            }
        });
    }

    saveManagementSoftwareFirstSyncDate = function(managementSoftware){
        console.log('saving firstSyncDate on Business');
        var $csrfToken = $('meta[name="csrf-token"]').attr('content');
        var $baseUrl = $('meta[name="base-url"]').attr('content');
        firstSyncDate = new Date().getTime();
        $.ajax({
            type: 'POST',
            url: $baseUrl + '/business/saveManagementSoftwareFirstSyncDate',
            async: false,
            data: {firstSyncDate: firstSyncDate, managementSoftware: managementSoftware, _token: $csrfToken}}
        ).done(function (firstSyncDate) {
            console.log('firstSyncDate saved!');
            $('meta[name="'  + managementSoftware + '-firstSyncDate"]').attr('content',firstSyncDate);
        });
        
    }

    verifyManagementSoftwareSync = function(){
        console.log('verify client sync');
        var synced = false;
        var $csrfToken = $('meta[name="csrf-token"]').attr('content');
        var $baseUrl = $('meta[name="base-url"]').attr('content');
        $.ajax({
            type: 'POST',
            url: $baseUrl + '/clients/verifyManagementSoftwareSync',
            async: false,
            data: {_token: $csrfToken}}
        ).done(function (response) {
            console.log(response);
            synced = response;
        }).fail(function(e){
            console.log(e);
        });
        return synced;
    }

});
